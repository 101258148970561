
let map, autocomplete, marker;

function initMap() {
    const defaultLocation = {
        lat: 39.526610,
        lng: -107.727261
    };
    map = new google.maps.Map(document.getElementById('map'), {
        center: defaultLocation,
        zoom: 5,
    });

    marker = new google.maps.Marker({
        position: defaultLocation,
        map: map,
        draggable: true,
    });

    autocomplete = new google.maps.places.Autocomplete(document.getElementById('address'), {
        types: ['geocode'],
    });

    autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place.geometry) {
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            updateLocation(lat, lng);
            reverseGeocode(lat, lng);
        }
    });

    marker.addListener('dragend', () => {
        const lat = marker.getPosition().lat();
        const lng = marker.getPosition().lng();

        updateLocation(lat, lng);

        reverseGeocode(lat, lng);
    });

    document.getElementById('get-location-btn').addEventListener('click', getCurrentLocation);
}

function updateLocation(lat, lng) {
    document.getElementById('latitude').value = lat;
    document.getElementById('longitude').value = lng;
    marker.setPosition({
        lat,
        lng
    });
    map.setCenter({
        lat,
        lng
    });
}

function reverseGeocode(lat, lng) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({
        location: {
            lat,
            lng
        }
    }, (results, status) => {
        if (status === 'OK' && results[0]) {
            const addressComponents = results[0].address_components;
            const formattedAddress = results[0].formatted_address;

            let city = '';
            let country = '';
            let zipCode = '';
            addressComponents.forEach((component) => {
                if (component.types.includes('locality')) {
                    city = component.long_name;
                }
                if (component.types.includes('country')) {
                    country = component.long_name;
                }
                if (component.types.includes('postal_code')) {
                    zipCode = component.long_name;
                }
            });

            document.getElementById('address').value = formattedAddress;
            document.getElementById('city').value = city;
            document.getElementById('country').value = country;
            document.getElementById('zip_code').value = zipCode;
        } else {
            console.error('Geocoder failed due to: ' + status);
        }
    });
}

function getCurrentLocation() {
    const btn = document.getElementById('get-location-btn');
    btn.disabled = true;
    btn.textContent = 'Fetching location...';

    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const lat = position.coords.latitude;
                const lng = position.coords.longitude;
                updateLocation(lat, lng);
                reverseGeocode(lat, lng);

                btn.disabled = false;
                btn.textContent = 'Get My Location';
            },
            (error) => {
                alert('Unable to fetch location. Please ensure location services are enabled.');
                btn.disabled = false;
                btn.textContent = 'Get My Location';
            }
        );
    } else {
        alert('Geolocation is not supported by your browser.');
        btn.disabled = false;
        btn.textContent = 'Get My Location';
    }
}

window.onload = initMap;
