// Hero section swiper

let hero_swiper = new Swiper(".hero-section .mySwiper", {
    autoplay: {
        delay: 5000,
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    navigation: {
            nextEl: ".hero-section .swiper-button-next",
            prevEl: ".hero-section .swiper-button-prev",
        },
});

// Function to adjust margins for the first and last visible slides
function updateMargins(swiperInstance) {
    const slides = swiperInstance.slides;
    const slidesPerView = swiperInstance.params.slidesPerView;
    

    // Only adjust margins if slidesPerView is greater than 1
    if (slidesPerView > 1) {
        slides.forEach((slide) => {
            const productCardOrImage = slide.querySelector('.product-card') || slide.querySelector('img') || slide.querySelector('video');
            if (productCardOrImage) {
                productCardOrImage.style.marginRight = '20px';
            }
        });
        // First visible slide
        const firstVisibleIndex = swiperInstance.activeIndex;
        const firstVisibleSlide = slides[firstVisibleIndex];
        if (firstVisibleSlide) {
            const firstVisibleCardOrImage = firstVisibleSlide.querySelector('.product-card') || firstVisibleSlide.querySelector('img') || firstVisibleSlide.querySelector('video');
            if (firstVisibleCardOrImage) {
                firstVisibleCardOrImage.style.marginLeft = '0';
            }
        }

        // Last visible slide (depends on slidesPerView)
        const lastVisibleIndex = firstVisibleIndex + slidesPerView - 1;
        const lastVisibleSlide = slides[lastVisibleIndex];
        if (lastVisibleSlide) {
            const lastVisibleCardOrImage = lastVisibleSlide.querySelector('.product-card') || lastVisibleSlide.querySelector('img') || lastVisibleSlide.querySelector('video');
            if (lastVisibleCardOrImage) {
                lastVisibleCardOrImage.style.marginRight = '0'; // No margin on the right
            }
        }
    } else{
        slides.forEach((slide) => {
            const productCardOrImage = slide.querySelector('.product-card') || slide.querySelector('img') || slide.querySelector('video');
            if (productCardOrImage) {
                productCardOrImage.style.marginRight = '0';
            }
        });
    }
}

// New Arrivals section swiper

document.addEventListener("DOMContentLoaded", function () {
    const swiper = new Swiper(".new-arrivals-section .swiper-container", {
        grid: {
            rows: 1,
            fill: "row",
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            576: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 3,
            },
            1024: {
                slidesPerView: 4,
            },
        },
        on: {
            init: function () {
                updatePagination(this);
                updateMargins(this);
            },
            slideChange: function () {
                updatePagination(this);
                updateMargins(this);
            },
        },
    });

    function updatePagination(swiperInstance) {
        const slidesPerView = swiperInstance.params.slidesPerView;
        const totalSlides = swiperInstance.slides.length;
        const slidesPerPage =
            slidesPerView * (swiperInstance.params.slidesPerColumn || 1);
        const totalPages = Math.ceil(totalSlides / slidesPerPage);
        const currentPage =
            Math.ceil(swiperInstance.realIndex / slidesPerPage) + 1;
        const paginationContainer = document.querySelector(
            ".new-arrivals-section .custom-pagination-pages"
        );
        paginationContainer.innerHTML = "";

        // Previous Button
        document.querySelector(".new-arrivals-section .prev-btn").disabled =
            currentPage === 1;
        document.querySelector(".new-arrivals-section .next-btn").disabled =
            currentPage === totalPages;

        // Pages and Ellipsis
        let pagesHtml = "";
        for (let i = 1; i <= totalPages; i++) {
            if (
                i === 1 ||
                i === totalPages ||
                (i >= currentPage - 1 && i <= currentPage + 1)
            ) {
                pagesHtml += `<span class="custom-pagination-page${
                    i === currentPage ? " active-page" : ""
                }" data-page="${i}">${i}</span>`;
            } else if (i === currentPage - 2 || i === currentPage + 2) {
                pagesHtml += '<span class="ellipsis">...</span>';
            }
        }
        paginationContainer.innerHTML = pagesHtml;

        // Add Event Listeners to Pages
        document
            .querySelectorAll(".new-arrivals-section .custom-pagination-page")
            .forEach((page) => {
                page.addEventListener("click", function () {
                    const pageIndex = this.getAttribute("data-page") - 1;
                    swiperInstance.slideTo(pageIndex * slidesPerPage);
                });
            });
    }

    // Add Event Listeners to Prev/Next Buttons
    $(".new-arrivals-section .prev-btn").on("click", function () {
        swiper.slidePrev();
    });

    $(".new-arrivals-section .next-btn").on("click", function () {
        swiper.slideNext();
    });

    window.addEventListener("resize", function () {
        if (swiper) {
            updateMargins(swiper);
        }
    });
});


// Related Products Section Swiper

let related_products_swiper = new Swiper(
    ".related-products-section .mySwiper",
    {
        navigation: {
            nextEl: ".related-products-section .swiper-button-next",
            prevEl: ".related-products-section .swiper-button-prev",
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            576: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 3,
            },
            1024: {
                slidesPerView: 4,
            },
        },
        on: {
            init: function () {
                updateMargins(this);
            },
            slideChange: function () {
                updateMargins(this);
            },
        },
    }
);

// Tech Talk Blog Page - Posts Swiper

document.addEventListener("DOMContentLoaded", function () {
    const swiper = new Swiper(".blog-section .swiper-container", {
        grid: {
            rows: 4,
            fill: "row",
        },
        spaceBetween: 15,
        breakpoints: {
            0: {
                slidesPerView: 1,
                grid: {
                    rows: 4,
                    fill: "row",
                },
            },
            986: {
                slidesPerView: 1,
                grid: {
                    rows: 4,
                    fill: "row",
                },
            },
            1004: {
                slidesPerView: 2,
                grid: {
                    rows: 4,
                    fill: "row",
                },
            },
            1215: {
                slidesPerView: 2,
                grid: {
                    rows: 4,
                    fill: "row",
                },
            },
        },
        on: {
            init: function () {
                updatePagination(this);
            },
            slideChange: function () {
                updatePagination(this);
            },
        },
    });

    function updatePagination(swiperInstance) {
        const slidesPerView = swiperInstance.params.slidesPerView;
        const slidesPerColumn = swiperInstance.params.grid.rows || 1;
        const slidesPerPage = slidesPerView * slidesPerColumn;
        const totalSlides = swiperInstance.slides.length;
        const totalPages = Math.ceil(totalSlides / slidesPerPage);

        // Calculate the current page
        const currentPage =
            Math.floor(swiperInstance.activeIndex + 1 / slidesPerPage) + 1;

        const paginationContainer = document.querySelector(
            ".blog-section .custom-pagination-pages"
        );
        paginationContainer.innerHTML = "";

        // Previous Button
        document.querySelector(".blog-section .prev-btn").disabled =
            currentPage === 1;
        document.querySelector(".blog-section .next-btn").disabled =
            currentPage === totalPages;

        // Pages and Ellipsis
        let pagesHtml = "";
        for (let i = 1; i <= totalPages; i++) {
            if (
                i === 1 ||
                i === totalPages ||
                (i >= currentPage - 1 && i <= currentPage + 1)
            ) {
                pagesHtml += `<span class="custom-pagination-page${
                    i === currentPage ? " active-page" : ""
                }" data-page="${i}">${i}</span>`;
            } else if (i === currentPage - 2 || i === currentPage + 2) {
                pagesHtml += '<span class="ellipsis">...</span>';
            }
        }
        paginationContainer.innerHTML = pagesHtml;

        // Add Event Listeners to Pages
        document
            .querySelectorAll(".blog-section .custom-pagination-page")
            .forEach((page) => {
                page.addEventListener("click", function () {
                    const pageIndex = this.getAttribute("data-page") - 1;
                    swiperInstance.slideTo(pageIndex * slidesPerPage);
                });
            });
    }

    // Add Event Listeners to Prev/Next Buttons
    $(".blog-section .prev-btn").on("click", function () {
        swiper.slidePrev();
    });

    $(".blog-section .next-btn").on("click", function () {
        swiper.slideNext();
    });
});

//frequently asked questions - Q&A

document.addEventListener("DOMContentLoaded", function () {
    const swiper = new Swiper("#Q-A-part .swiper-container", {
        navigation: {
            nextEl: "#Q-A-part .next-btn",
            prevEl: "#Q-A-part .prev-btn",
        },
        slidesPerView: 1,
        grid: {
            rows: 4,
            fill: "row",
        },
        spaceBetween: 15,
    });
});

// frequently asked questions - related articles

document.addEventListener("DOMContentLoaded", function () {
    const swiper = new Swiper(".related-articles-section .swiper-container", {
        grid: {
            rows: 1,
            fill: "row",
        },
        spaceBetween: 15,
        breakpoints: {
            0: {
                slidesPerView: 1,
                grid: {
                    rows: 1,
                    fill: "row",
                },
            },
            986: {
                slidesPerView: 2,
                grid: {
                    rows: 1,
                    fill: "row",
                },
            },
            1004: {
                slidesPerView: 3,
                grid: {
                    rows: 1,
                    fill: "row",
                },
            },
        },
    });
});

// You May Also Like Swiper - Blog Details Page

let you_may_also_like_swiper = new Swiper(
    "#you-may-also-like .mySwiper",
    {
        navigation: {
            nextEl: "#you-may-also-like .swiper-button-next",
            prevEl: "#you-may-also-like .swiper-button-prev",
        },
        
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            1004: {
                slidesPerView: 3,
            },
            986: {
                slidesPerView: 2,
            },
            1215: {
                slidesPerView: 4,
            },
        },
    }
);