let map, autocomplete, marker;

function initMap() {
    const chinaBounds = {
        north: 53.560973,
        south: 18.194567,
        west: 73.499734,
        east: 135.085604,
    };

    const defaultLocation = {
        lat: 39.526610,
        lng: 116.727261,
    };

    map = new google.maps.Map(document.getElementById('map'), {
        center: defaultLocation,
        zoom: 5,
        restriction: {
            latLngBounds: chinaBounds,
            strictBounds: true,
        },
    });

    marker = new google.maps.Marker({
        position: defaultLocation,
        map: map,
        draggable: true,
    });

    autocomplete = new google.maps.places.Autocomplete(document.getElementById('address'), {
        types: ['geocode'],
        componentRestrictions: { country: 'cn' },
    });

    autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place.geometry) {
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            const country = getCountryFromPlace(place);
            if (country === 'China') {
                updateLocation(lat, lng);
                reverseGeocode(lat, lng);
            } else {
                showToast('We currently support locations only within China.');
                document.getElementById('address').value = '';
            }
        }
    });

    marker.addListener('dragend', () => {
        const lat = marker.getPosition().lat();
        const lng = marker.getPosition().lng();
        reverseGeocode(lat, lng);
    });

    map.addListener('click', (event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        reverseGeocode(lat, lng);
    });

    document.getElementById('get-location-btn').addEventListener('click', getCurrentLocation);
}

function reverseGeocode(lat, lng) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === 'OK' && results[0]) {
            const addressComponents = results[0].address_components;
            const formattedAddress = results[0].formatted_address;

            let city = '';
            let country = '';
            let zipCode = '';
            addressComponents.forEach((component) => {
                if (component.types.includes('locality')) {
                    city = component.long_name;
                }
                if (component.types.includes('country')) {
                    country = component.long_name;
                }
                if (component.types.includes('postal_code')) {
                    zipCode = component.long_name;
                }
            });

            if (country === 'China') {
                document.getElementById('address').value = formattedAddress;
                document.getElementById('country').value = country;
                document.getElementById('city').value = city;
                document.getElementById('zip_code').value = zipCode;
            } else {
                showToast('We currently support locations only within China.');
                updateLocation(39.526610, 116.727261);
            }
        } else {
            console.error('Geocoder failed due to: ' + status);
        }
    });
}

function getCurrentLocation() {
    const btn = document.getElementById('get-location-btn');
    btn.disabled = true;
    btn.textContent = 'Fetching location...';

    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const lat = position.coords.latitude;
                const lng = position.coords.longitude;
                reverseGeocode(lat, lng);

                btn.disabled = false;
                btn.textContent = 'Get My Location';
            },
            (error) => {
                showToast('Unable to fetch location. Please ensure location services are enabled.');
                btn.disabled = false;
                btn.textContent = 'Get My Location';
            }
        );
    } else {
        showToast('Geolocation is not supported by your browser.');
        btn.disabled = false;
        btn.textContent = 'Get My Location';
    }
}

function getCountryFromPlace(place) {
    let country = '';
    if (place.address_components) {
        place.address_components.forEach((component) => {
            if (component.types.includes('country')) {
                country = component.long_name;
            }
        });
    }
    return country;
}

function showToast(message) {
    Swal.fire({
        icon: "error",
        title: "Sorry",
        html: `
            <h5><strong>${message}</strong></h5><br>
            <p>For shipping outside China, please contact us at:<br><strong>info@eledtric.cn</strong></p>
        `,
    });
}

window.onload = initMap;
