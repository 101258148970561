// Open and Close Search Bar Toggle
const searchBlock = document.querySelector(".search-block");
const searchToggle = document.querySelector(".search-toggle");
const searchCancel = document.querySelector(".search-cancel");

if (searchToggle && searchCancel) {
   searchToggle.addEventListener("click", () => {
      searchBlock.classList.add("is-active");
   });

   searchCancel.addEventListener("click", () => {
      searchBlock.classList.remove("is-active");
   });
}

/* Sidebar */

  $(document).ready(function () {
    $("#sidebarCollapse").on("click", function () {
      $("#sidebar").addClass("active");
      $(".overlay").addClass("visible");
    });

    $("#sidebarCollapseX").on("click", function () {
      $(".overlay").removeClass("visible");
      $("#sidebar").removeClass("active");
    });

    $(".overlay").on("click", function () {
      $("#sidebar").removeClass("active");
      $(".overlay").removeClass("visible");
    });
  });

/* COLORS BTN */

const buttons = document.querySelectorAll(".colors .btn");

buttons.forEach((button) => {
  button.addEventListener("click", () => {
    buttons.forEach((btn) => btn.classList.remove("active"));
    button.classList.add("active");
  });
});

/* product quantity */

$("#decrement").click(function () {
  let currentQuantity = parseInt($("#quantity").val());
  if (currentQuantity > parseInt($("#quantity").attr("min"))) {
    $("#quantity").val(currentQuantity - 1);
  }
});

$("#increment").click(function () {
  let currentQuantity = parseInt($("#quantity").val());
  $("#quantity").val(currentQuantity + 1);
});

/* cart product quantity */

$(".cart-section").on("click", ".decrement", function () {
  let $cartCard = $(this).closest(".cart-card");
  let $quantity = $cartCard.find(".quantity");
  let currentQuantity = parseInt($quantity.text());
  let minQuantity = 1;

  if (currentQuantity > minQuantity) {
    currentQuantity--;
    $quantity.text(currentQuantity);
    updateTotal($cartCard, currentQuantity);
    updateOverallTotal();
  }
});

$(".cart-section").on("click", ".increment", function () {
  let $cartCard = $(this).closest(".cart-card");
  let $quantity = $cartCard.find(".quantity");
  let currentQuantity = parseInt($quantity.text());

  currentQuantity++;
  $quantity.text(currentQuantity);
  updateTotal($cartCard, currentQuantity);
  updateOverallTotal();
});

function updateTotal($cartCard, quantity) {
  let priceText = $cartCard.find(".price").text();
  let price = parseFloat(priceText.replace("£", ""));
  let total = price * quantity;

  $cartCard.find(".total").text("£" + total.toFixed(2));
}

function updateOverallTotal() {
  let overallTotal = 0;

  $(".cart-section .cart-card").each(function () {
    let totalText = $(this).find(".total").text();
    let total = parseFloat(totalText.replace("£", "").replace(",", ""));
    if (!isNaN(total)) {
      overallTotal += total;
    }
  });

  $(".total-price").text("£" + overallTotal.toFixed(2));
}

// Initial calculation of overall total
updateOverallTotal();

// Toggle for account details password
$("#toggle-password").on("click", function () {
  let passwordInput = $("#change-password");
  let icon = $(this);

  if (passwordInput.attr("type") === "password") {
    passwordInput.attr("type", "text");
    icon.removeClass("bx-hide").addClass("bx-show");
  } else {
    passwordInput.attr("type", "password");
    icon.removeClass("bx-show").addClass("bx-hide");
  }
});

// Show the loader when the page starts loading
window.addEventListener('beforeunload', () => {
  document.getElementById('global-loader').style.display = 'flex';
});

// Hide the loader once the page is fully loaded
window.addEventListener('load', () => {
  document.getElementById('global-loader').style.display = 'none';
});    